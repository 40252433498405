import React from 'react';

const Header = () => {

  return (
    <nav className='space-x-10'>
      <ul className='flex flex-row space-x-4 md:space-x-10 md:text-xl justify-center md:justify-end font-thin'>
        <li className="px-3" data-aos="fade-down" data-aos-duration="500" >
          <a href='#projets' className='py-1 hover:bg-gray focus:bg-gray rounded-lg link link--metis'>
            Projets
          </a>
        </li>
        <li className="px-3" data-aos="fade-down" data-aos-duration="500" data-aos-delay="100">
          <a href='#contact' className='py-1 hover:bg-gray focus:bg-gray rounded-lg link link--metis'>
            Contact
          </a>
        </li>
        <li className="px-3" data-aos="fade-down" data-aos-duration="500" data-aos-delay="200">
          <a href='./Curriculum Vitae - Paul CHAMPMARTIN.pdf' target='_blank' rel='noreferrer' className='py-1 hover:bg-gray focus:bg-gray rounded-lg link link--metis'>
            Curriculum Vitae
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
