import { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import LazyLoad from 'react-lazyload';

import Header from './components/Header';
import Hero from './components/Hero';
import Projets from './components/Projets';
import Competences from'./components/Competences';
import Contact from './components/Contact';


function App() {
	useEffect(() => {
    AOS.init({
      duration: 600,
      once: true
    });
  }, []);
  return (
      <div className='App container my-10 mx-auto max-w-screen-xl'>
	  <LazyLoad height={200} once >
        <Header />
		</LazyLoad>
        <main>
          <Hero />
		  <LazyLoad height={200} once >
          <Projets />
		  </LazyLoad>
		  <LazyLoad height={200} once >
          <Competences />
		  </LazyLoad>
		  <LazyLoad height={200} once >
          <Contact />
		  </LazyLoad>
        </main>
      </div>
  );
}

export default App;
