import React from 'react';

const Competences = () => {

  return (
    <section className='my-28  px-5'>
      <header data-aos="fade-down" className='text-2xl font-bold pt-10'>
        <h2>Compétences</h2>
      </header>
      <div className='my-7 grid gap-5 grid-cols-3 md:grid-cols-6'>
        <div className='flex flex-col items-center' tabIndex='0' role='img' aria-label='Javascript' data-aos="fade-down" data-aos-duration="300">
          <img src='./images/icons/javascript-original.svg' alt='' loading='lazy' className='w-16 mb-1' width='64px' height='64px' />
          Javascript
        </div>
        <div className='flex flex-col items-center' tabIndex='0' role='img' aria-label='React Js' data-aos="fade-down" data-aos-duration="300" data-aos-delay="100">
          <img src='./images/icons/react-original.svg' alt='' loading='lazy' className='w-16 mb-1' width='64px' height='64px' />
          React JS
        </div>
        <div className='flex flex-col items-center' tabIndex='0' role='img' aria-label='PHP' data-aos="fade-down" data-aos-duration="300" data-aos-delay="200">
          <img src='./images/icons/php-original.svg' alt='' loading='lazy' className='w-16 mb-1' width='64px' height='64px' />
          PHP
        </div>
        <div className='flex flex-col items-center' tabIndex='0' role='img' aria-label='Prestashop' data-aos="fade-down" data-aos-duration="300" data-aos-delay="300">
          <img src='./images/icons/prestashop-original.svg' alt='' loading='lazy' className='w-16 mb-1' width='64px' height='64px' />
          Prestashop
        </div>
        <div className='flex flex-col items-center' tabIndex='0' role='img' aria-label='HTML 5' data-aos="fade-down" data-aos-duration="300" data-aos-delay="400">
          <img src='./images/icons/html5-original.svg' alt='' loading='lazy' className='w-16 mb-1' width='64px' height='64px' />
          HTML 5
        </div>
        <div className='flex flex-col items-center' tabIndex='0' role='img' aria-label='CSS 3' data-aos="fade-down" data-aos-duration="300" data-aos-delay="500">
          <img src='./images/icons/css3-original.svg' alt='' loading='lazy' className='w-16 mb-1' width='64px' height='64px' />
          CSS 3
        </div>
      </div>
    </section>
  );
};

export default Competences;
