import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-web';
import animate from 'lottie-web';
import animationData from './lottie/animation2';

const Contact = () => {
 const container = useRef(null);

  useEffect(() => {
    Lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });
  }, [container]);
  return (
    <section className='my-28' id='contact'>
      <header className='px-5 text-2xl font-bold pt-10' data-aos="fade-down">
        <h2>Me contacter</h2>
        <p className='text-base font-thin'>Une idée ? Un projet ? Une question ? N'hésitez pas à me contacter !</p>
      </header>
      <div className='md:mx-6 flex flex-col flex-wrap md:flex-row justify-between' data-aos="fade-right">
        <div className='md:w-6/12 md:px-0 p-5 my-5'>
          <div>
            <p className='font-bold'>Paul CHAMPMARTIN</p>
            <p className='font-thin'>Montpellier, FRANCE</p>
            <a href='mailto: paul.champmartin@gmail.com' className='border-b-2 mt-3 inline-block border-gray-500'>
              paul.champmartin@gmail.com
            </a>
          </div>
          <div className='flex flex-row flex-wrap mt-7'>
            <div className='mr-4'>
              <a href='https://www.linkedin.com/in/champmartin-paul/' target='_blank' rel='noreferrer'>
                <span className='mb-2 flex flex-row items-end border border-gray-500 p-2 rounded-lg text-sm'>
                  <img src='./images/icons/linkedin.svg' alt='' width='24px' height='24px' className='mr-1' />
                  LinkedIn
                </span>
              </a>
            </div>

            <div className='mr-4'>
              <a href='https://github.com/paulcpn' target='_blank' rel='noreferrer'>
                <span className='mb-2 flex flex-row items-end border border-gray-500 p-2 rounded-lg text-sm'>
                  <img src='./images/icons/github.svg' alt='' width='24px' height='24px' className='mr-1' />
                  GitHub
                </span>
              </a>
            </div>
          </div>
		  <div className='container w-2/4 m-auto' ref={container}>
		  </div>
        </div>

        <div className='bg-gray px-5 py-10 md:py-8 sm:p-8 my-2 md:rounded-lg shadow-lg  justify-between w-full  md:w-6/12 ' data-aos="fade-left">
          <form className='flex flex-col space-y-3 m-auto w-full' name='contact' action='https://formspree.io/f/xvodaray' method='post'>
            <input type='hidden' name='form-name' value='contact' />
            <label htmlFor='name'>Nom</label>
            <input type='text' name='name' id='name' className='gradient' required></input>
            <label htmlFor='email'>Email</label>
            <input type='email' name='email' id='email' className='gradient' required></input>
            <label htmlFor='message'>Message</label>
            <textarea name='message' id='message' cols='25' rows='5' className='gradient' required></textarea>
            <button type='submit' className='border-gradient px-4 py-2 rounded-lg w-auto mr-auto shadow-md'>
              Envoyer
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
