const projects = [
  {
    title: 'Home Finder',
    image: './images/home-finder',
    description: 'Conception d\'une application Web agrégeant les annonces immobilières de différents sites.',
    tools: ['HTML', 'CSS', 'Javascript', 'PHP', 'MySQL'],
  },
  {
    title: 'Serious Game du C.E.S.U',
    image: './images/serious-game',
    description: 'Réalisation d\'une application Web pour la formation du personnel de santé aux situations d\'urgence.',
    tools: ['HTML', 'CSS', 'Javascript', 'JQuery', 'MySQL'],
  },
  {
    title: 'API - Planification médicale',
    image: './images/api',
    description: 'Création d\'un logiciel et d\'une application mobile destinés à simplifier l\'itinéraire des visites médicales triées .',
    tools: ['PHP', 'Java', 'MySQL'],
  },
];

export default projects;
