import React, { useEffect, useRef }  from 'react';
import Lottie from 'lottie-web';
import animate from 'lottie-web';
import animationData from './lottie/animation1';

const Hero = () => {
 const container = useRef(null);

  useEffect(() => {
    Lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });
  }, [container]);
  return (
    <section className='flex justify-center mt-28 mb-24 hero-content'>
      <div className='my-44 hright'>
        <h2 className='text-4xl md:text-4xl opacity' data-aos="fade-down">Bonjour <span className="emoji text-3xl md:text-4xl align-middle wave">🖐</span></h2>
		<h1 className='text-3xl md:text-3xl' data-aos="fade-down"  data-aos-delay="100">Je suis <span className="font-bold">Paul Champmartin</span></h1>
        <p className='tracking-wide leading-relaxed' data-aos="fade-right"  data-aos-delay="300">
         Passioné par le web et le graphisme, je suis actuellement <span className="font-bold">Développeur Web</span> dans une agence de communication innovante sur <span className="font-bold">Montpellier</span>.
        </p>
		<span className="scroll-btn mx-8">
		<a className="mouse"  href="#projects" data-aos="fade-down"  data-aos-delay="500">
			<span>
			</span>
		</a>
	</span>
      </div>
	  <div className='p-8 hleft' data-aos="fade-left"  data-aos-delay="300">
        <div className='container w-3/4 m-auto' ref={container}>
	  </div>
      </div>
    </section>
  );
};

export default Hero;
